.home-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .my-link {
    padding: 2px 15px;
    font-size: 13px;
    width: 88%;
    @include mobile {
      font-size: 10px !important;
      height: 30px;
      line-height: 30px;
      width: 80%;
    }
  }
  .personal-pho {
    position: relative;
    cursor: pointer;
    font-size: 13px;
    img {
      width: 100%;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .link-icon-list {
    position: absolute;
    right: 0;
    top: 50px;
    background-color: $white-color;
    width: 180px;
    padding: 0 15px;
    border-radius: $btn-input-radius;
    z-index: 8;
    .link-icon {
      background-color: transparent !important;
      color: $grad-color;
      text-align: start;
      border-radius: 0;
      width: 100%;
      transition: $transition;
      display: flex;
      align-items: center;
      line-height: 1.3;
      height: auto;
      padding: 10px;
      svg {
        margin-right: 10px;
        width: 19px;
        min-width: 19px;
        path {
          fill: $grad-color;
        }
      }
      span {
        display: block;
      }
      &:hover {
        color: $main-color;
        svg {
          path {
            fill: $main-color;
          }
        }
      }
    }
    .link-icon:first-of-type {
      border-bottom: 1px solid $border-color;
    }
  }
  .link-icon-list.show {
    display: none;
  }
  .personal-info {
    width: 197px;
    height: 48px;
    line-height: unset;
    padding: 12px 0;
    font-size: 11px;
    color: $text-color !important;
    span {
      color: $grad-color !important;
      font-size: 9px;
    }
  }
  .lang-switch {
    position: relative;
    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      cursor: pointer;
    }
    dropdown {
      display: inline-block;
      height: 37px;
      line-height: 37px;
      width: 37px;
      text-align: center;
      color: $text-color;
      font-weight: 600;
    }
    dropdown label,
    dropdown ul li {
      display: none;
      width: 200px;
      background: #fff;
      padding: 15px 20px;
      border-radius: $btn-input-radius;
    }
    dropdown label {
      top: 4px !important;
    }
    dropdown ul li {
      top: 54px;

      border: 1px solid $border-color;
    }
    dropdown label:hover,
    dropdown ul li:hover {
      text-decoration: underline;
      color: black;
      cursor: pointer;
    }
    dropdown label {
      color: #000000;
      position: relative;
      z-index: 2;
      top: 0;
    }
    dropdown input {
      display: none;
    }
    dropdown input ~ ul {
      position: relative;
      visibility: hidden;
      opacity: 0;
      top: -20px;
      z-index: 1;
    }
    dropdown input:checked + label {
      background: #ffffff;
      color: #000000;
    }

    dropdown input:checked ~ ul {
      visibility: visible;
      opacity: 1;
      top: 0;
    }
    .animate {
      transition: all 0.3s;
      backface-visibility: hidden;
      padding: 0;
      position: absolute;
      top: 44px;
      width: 37px;
      height: 37px;
      line-height: 37px;
    }
    @include mobile {
      dropdown,
      .animate {
        font-size: 10px;
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    }
  }
  @include mobile {
    .personal-pho {
      height: 30px;
      width: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.my-link {
  line-height: 40px;
  font-size: 14px;
  border-radius: $btn-input-radius;
  background-color: $white-color;
  position: relative;
  .link-text {
    margin: 0;
    color: $grad-color;
    @include flex(center, space-between);
    span {
      a {
        color: $black-color;
      }
    }

    .link-icon-to-list {
      cursor: pointer;
      position: relative;
    }

    @include mobile {
      font-size: 10px;
      line-height: 25px;
    }
  }
}
