@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html {
  overflow-x: hidden;
  font-size: 100%;
  width: 100%;
  @include tablet {
    font-size: 80%;
  }
  @include mobile {
    font-size: 60%;
  }
}
body {
  font-family: $font-family;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  margin: 0 !important;
  // color: $txt-color;
  @include mobile {
    margin-bottom: 50px;
  }
  p {
    margin: 0;
  }
}

.progress-bar {
  background-color: $main-color;
}

// Custom Intro js
.introjs-tooltip-header {
  display: flex;
  color: #8055f0 !important;
  justify-content: space-between;
  align-items: center;
}
.introjs-nextbutton {
  background-color: #8055f0 !important;
  color: white !important;
  text-shadow: unset !important;
}
.introjs-prevbutton {
  background-color: white !important;
  color: #8055f0 !important;
  text-shadow: unset !important;
  border: 1px solid #8055f0 !important;
}

.introjs-skipbutton {
  position: relative !important;
}
.introjs-tooltipbuttons {
  display: flex;
  justify-content: space-between;
}
.introjs-bullets ul {
  display: flex !important;
  justify-content: center;
}
.introjs-tooltipbuttons::after {
  content: unset !important;
}
.introjs-tooltipReferenceLayer,
.introjs-tooltip-title,
.introjs-tooltiptext,
.introjs-button {
  font-family: $font-family !important;
}
