$main-color: #8055f0;
$white-color: #fff;
$black-color: #000;
$nav-color: #d2d2d2;
$text-color: #163152;
$blue-color: #8cc8cc;
$pargh-color: #bfbfbf;
$grad-color: #969ba3;
$back-color: #f3f3f39c;
$border-color: #dee3ed8a;

$btn-input-padding: 12px 30px;
$btn-input-padding-mobile: 10px;
$btn-input-radius: 6px;
$transition: all 0.3s ease-in-out;

$box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);

$header-height: 80px;
$header-shrink-height: 60px;

$mobile-width: 1024px;
$tablet-width: 1024px;
$desktop-width: 20000px;

$font-family: "Montserrat", sans-serif;
