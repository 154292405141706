.sidebar {
  height: 100%;
  width: 255px;
  padding: 17px;
  position: fixed;
  background-color: $main-color;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  box-sizing: border-box;
  transition: $transition;
  z-index: 8;
  .logo {
    z-index: 1;
    margin-bottom: 20px;
    @include flex(center, space-between);
    img {
      width: 100px;
    }
  }
  .sidebarList {
    width: 100%;
    z-index: 1;
    .list-item {
      margin-bottom: 10px;
      a {
        @include flex(center, unset);
        flex-direction: row;
        cursor: pointer;
        color: $white-color;
        text-decoration: none;
        width: 100%;
        transition: $transition;
        padding: 12px 5px;
        border-radius: $btn-input-radius;
        &:hover {
          background-color: $white-color;
          color: $main-color;
          .icon {
            svg {
              path {
                stroke: $main-color;
              }
            }
          }
        }
        &.active {
          background-color: $white-color;
          color: $main-color;
          .icon {
            svg {
              path {
                stroke: $main-color;
              }
            }
          }
        }
        .icon {
          margin-right: 10px;
          svg {
            path {
              stroke: $white-color;
            }
          }
        }
      }
    }
    li:first-of-type,
    li:nth-child(5) {
      a {
        &:hover {
          .icon {
            svg {
              path {
                stroke: transparent;
                fill: $main-color;
              }
            }
          }
        }
        &.active{
          .icon {
            svg {
              path {
                stroke: transparent;
                fill: $main-color;
              }
            }
          }
        }
      }
      .icon {
        svg {
          path {
            fill: $white-color;
            stroke: transparent;
          }
        }
      }
    }
  }
  .upgrad {
    height: 188px;
    position: relative;
    z-index: 1;
    @include flex(flex-end, center);
    font-size: 13px;
    img {
      width: 130px;
      position: absolute;
      top: 0;
    }
    .box {
      padding: 20px 10px 10px;
      width: 100%;
      height: 128px;
      background-color: $white-color;
      border-radius: $btn-input-radius;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .shap1 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .shap2 {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  @include mobile {
    left: -100%;
  }
}
.upgrad-btn {
  padding: $btn-input-padding;
  border-radius: $btn-input-radius;
  background-color: $main-color;
  color: $white-color !important;
  text-decoration: none;
}
.icon-mobile {
  display: none;
  z-index: 9;
  .form-button {
    width: 35px;
    border-radius: 0 15px 15px 0;
    background-color: $main-color;
  }
  @include mobile {
    display: block;
    position: fixed;
    top: 10px;
    left: 0;
  }
}
.close-icon {
  display: none;
  .form-button {
    background-color: transparent;
    width: unset;
    svg {
      fill: $white-color;
      font-size: 20px;
    }
  }
  @include mobile {
    display: block;
  }
}
.sidebar.show {
  @include mobile {
    left: 0;
    z-index: 10;
  }
}
.overlay {
  display: none;
  transition: $transition;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.6);
  z-index: 9;
}
.overlay.show {
  display: block;
}
