.add-description {
  .high-header {
    font-weight: 600;

    p {
      font-size: 15px;
    }
  }

  .form-control {
    width: 100%;
    margin: 30px 0;
    font-size: 13px;
  }
}

.color-div {
  @include flex(center, space-between);
}

.input-color-box-parent {
  margin-bottom: 20px;

  h3 {
    font-size: 13px;
    font-weight: bold;
    color: #222;
    margin-bottom: 15px;
  }

  .delete-icon-wrap {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 0;
  }
}

.input-color-box {
  position: relative;
  width: 150px;
  height: 40px;
  overflow: hidden;
  border: 2px solid #ccc;
  border-radius: 5px;

  input[type="color"] {
    -webkit-appearance: none;
    border: none;

    position: absolute;
    left: 0;
    width: 100% !important;
    height: 100%;
    top: 0;
    opacity: 0;
    z-index: 4;
    cursor: pointer;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #000;
    font-size: 16px;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
}
.background-back {
  width: 150px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}
