.cropper-crop-box {
	border-radius: 50%;
}

.cropper-view-box {
	border-radius: 50%;
	box-shadow: 0 0 0 1px #39f;
	outline: 0;
}

.btn-cancel {
	color: #6610f2;
	background: transparent !important;
	border: 1px solid #6610f2;
}