.drop-pdf {
  text-align: center;
  font-size: 13px;
  input[type="file"] {
    color: $white-color;
    width: 100px;
    z-index: 2;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -146%);
    opacity: 0;
  }
  input[type="file"]::-webkit-file-upload-button {
    border: none;
    color: $pargh-color;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    font-weight: 600;
    width: 100% !important;
  }
  p {
    color: $pargh-color;
  }
}
.more-size {
  @include flex(center, center);
}
.pro-btn,
.new-btn {
  display: inline-block;
  .form-button {
    width: auto;
    background-color: $main-color;
    font-size: 9px;
    padding: 3px 5px;
    display: inline-block;
    height: auto;
    line-height: unset;
    margin: 0 15px;
    border-radius: 3px;
    color: $white-color;
  }
}
.new-btn {
  .form-button {
    background: #00ce67;
  }
}
.file-info,
.images-uploads {
  // border-top: 1px solid $border-color;
  text-align: left;
}
