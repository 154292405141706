.single-item-switch {
  position: relative;
  width: 42px;
  margin-right: 15px;
  .checkbox{
    width: 42px;
    height: 21px;
  }
  input[type="checkbox"] {
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    background: $nav-color;
    outline: none;
    border-radius: 30px;
    position: relative;
    transition: all 0.4s;
    &:nth-child(3) {
      top: 150px;
      left: 40%;
    }
    &:nth-child(2) {
      top: 220px;
      left: 40%;
    }
    &::before {
      content: "";
      position: absolute;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: $white-color;
      top: 48%;
      left: 3px;
      transform: translateY(-50%);
      transition: all 0.6s;
    }
    &:checked {
      background: $blue-color;
      &::before {
        left: 100%;
        transform: translate(-120%, -50%);
      }
    }
  }
  @include mobile {
    width: 20px;
    .checkbox{
      width: 20px;
      height: 10px;
    }
    input[type="checkbox"] {
      &:nth-child(3) {
        top: 150px;
        left: 40%;
      }
      &:nth-child(2) {
        top: 220px;
        left: 40%;
      }
      &::before {
        height: 6px;
        width: 6px;
        top: 50%;
        left: 2px;
      }
      &:checked {
        &::before {
          left: 100%;
        }
      }
    }
  }

}
