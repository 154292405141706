.form-page {
  @include flex(start, space-between);
  width: 100%;
}
.form-control {
  padding: 0;
  // width: calc(100% - 207px);
  width: 100%;
  border: none;
  font-size: 14px;
  .form-input {
    padding: $btn-input-padding;
    border-radius: $btn-input-radius;
    background-color: $back-color;
    color: $grad-color;
    outline: none;
    border: none;
    width: 100%;
    height: 48px;
    line-height: 48px;
    display: block;
    &::placeholder {
      color: $grad-color;
    }
  }
  .text-area {
    height: 110px;
  }
  .error-mes {
    padding: $btn-input-padding;
    font-size: 13px;
    color: $blue-color;
  }
  @include mobile {
    // width: calc(100% - 110px);
    font-size: 10px;
    .form-input {
      padding: 0 10px;
      height: 30px;
      line-height: 30px;
    }
    .error-mes {
      font-size: 10px;
      padding: $btn-input-padding-mobile;
    }
  }
}
.form-button {
  width: 197px;
  height: 48px;
  line-height: 48px;
  border-radius: $btn-input-radius;
  background-color: $blue-color;
  color: $white-color;
  border: none;
  outline: none;
  transition: $transition;
  &.main-btn{
    background: $main-color;
  }
  &:hover {
    background-color: #64c1c7;
  }
  @include mobile {
    width: 97px;
    height: 30px;
    line-height: 30px;
  }
  &[disabled] {
    opacity: 0.5;
    background-color: $grad-color;
  }
}
.link-page,
.location-page,
.banks-page {
  .form-control {
    width: calc(100% - 207px);
    @include mobile {
      width: calc(100% - 110px);
    }
  }
}
.social-page {
  .form-page {
    width: unset;
  }
  .form-head {
    justify-content: center;
    @include mobile {
      flex-wrap: wrap;

    }
    .form-button{
      border-radius: 50px;
      @include mobile {
        width: 100% !important;
        margin-bottom: 15px;

      }
    }
    .form-control {
      width: 300px !important;
      margin-right:20px;
      input{
        border-radius: 50px;
      }
      @include mobile {
        width: 100% !important;
        margin-bottom: 15px;
      }
    }
  }
  .link-action {
    .form-page {
      .form-control {
        width: unset;
        margin-right: 10px;
        .form-input {
          padding: 0 5px;
          height: 40px;
          line-height: 40px;
          color: $text-color;
        }
      }
    }
  }
  .css-1k94rgl-Control {
    border-radius: $btn-input-radius;
    background-color: $back-color;
  }
}
.datepicker {
  margin-bottom: 26px;
}
