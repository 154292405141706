.single-item {
  padding: 20px;
  border: 1px solid $border-color;
  border-radius: $btn-input-radius;
  @include flex(center, space-between);

  .link-and-icon {
    @include flex(center, start);
    width: 50%;
    > img {
      width: 10px;
      margin-right: 10px;
    }
    @include mobile {
      width: 60%;
    }
  }
  .single-item-icon,
  .single-item-img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    background-color: $blue-color;
    color: $white-color;
    border-radius: $btn-input-radius;
    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
    @include flex(center, center);
    @include mobile {
      width: 40px;
      height: 40px;
      margin-right: 5px;
      img {
        width: 25px;
        height: 25px;
        object-fit: contain;
      }
    }
   
  }
  .single-item-img {
    width: 55px;
    height: 55px;
    background-color: transparent;
    img {
      width: 100%;
      height: 100%;
      border-radius: $btn-input-radius;
    }
    @include mobile {
      width: 25px;
      height: 25px;
    }
  }
  .single-item-info {
    width: 55%;
    margin-left: 15px;
    overflow: hidden;
    .name-from-link {
      font-weight: 700;
      margin-bottom: 5px;
      margin: 0;
    }
    .the-link {
      color: $blue-color;
      font-size: 12px;
      img {
        margin-right: 5px;
      }
      @include mobile {
        font-size: 7px;
        img {
          width: 10px;
        }
      }
    }
    @include mobile {
      width: 60%;
      margin-left: 5px;
    }
  }
 
  @include mobile {
    padding: 10px;
  }
}
.link-action {
  color: $text-color;
  cursor: pointer;
  @include flex(center, center);
  .edit-icon,
  .trash-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: $text-color;
    color: $white-color;
    border-radius: $btn-input-radius;
    @include mobile {
      width: 20px;
      height: 20px;
      line-height: 20px;
      svg {
        width: 10px;
      }
    }
  }
  .trash-icon {
    background-color: $back-color;
    color: $text-color;
  }
  .edit-icon {
    margin-right: 10px;
  }
  svg {
    width: 18px;
  }
}
.pdf {
  .name-from-link {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    background-color: $main-color;
    border-radius: 5px;
    a.pdf-title {
      text-decoration: none;
      color: $white-color;
    }
  }
}
