.drop-img,
.drop-pdf {
  width: 100%;
  display: block;
  // min-height: 200px;
  padding: 30px;
  border: dashed 2px #ccc !important;
  border-radius: $btn-input-radius;
  position: relative;
  .upload__image-wrapper {
    font-size: 13px;
    text-align: center;
    button {
      border: none;
      outline: none;
      background-color: transparent;
      color: #969ba3;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // padding: 0;
      // width: 49px;
      // height: 49px;
      // margin: 0;
    }
    .image-item {
      display: inline-block;
      margin: 15px 10px;
    }
    p {
      font-size: 10px;
      .form-button {
        background-color: $main-color;
        color: $white-color;
      }
    }
    .remove-img {
      color: $blue-color;
    }
  }
  .upload {
    position: absolute;

    text-align: center;
    left: 50%;
    transform: translate(-50%, -146%);
    z-index: 1;
    color: $blue-color;
    font-weight: 600;
  }
}
.link-page {
  .drop-img {
    padding: 0;
    width: 49px;
    height: 49px;
    position: relative;
    .upload__image-wrapper {
      width: 100%;
      height: 100%;
    }
    .img-upload {
      margin: 0 !important;
      height: 100%;
      position: relative;
      svg {
        width: 25px;
        height: 25px;
      }
    }
    .images-uploads {
      width: 100%;
      height: 100%;
      position: absolute;
      .image-item {
        width: 100%;
        height: 100%;
        margin: 0 !important;
        border-radius: 6px;
        overflow: hidden;
        img {
          width: 100%;
          margin: 0;
        }
        .image-item__btn-wrapper {
          button {
            position: absolute;
            top: -5px;
            right: 0;
            margin: 0;
            svg {
              width: 12px;
              path {
                fill: $white-color;
              }
            }
          }
        }
      }
    }
  }
}
