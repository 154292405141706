.payments,
.images {
  .p-payment {
    font-size: 13px;
    color: $pargh-color;

    @include mobile {
      font-size: 10px;
    }
  }

  .nav {
    @include flex(center, center);

    .nav-link {
      border-radius: 25px;
      font-size: 15px;
      background-color: $back-color;
      margin-right: 15px;
      width: 200px;

      &.active {
        background-color: $text-color;
      }

      @include mobile {
        font-size: 11px;
        width: 142px;
      }
    }
  }

  .packages {
    .nav {
      @include flex(center, center);
      background-color: $back-color;
      width: 100%;
      max-width: 320px;
      padding: 12px 0;
      margin: 0;

      .nav-link {
        border-radius: $btn-input-radius;
        font-size: 13px;
        padding: 12px 0;
        width: 70px;
        margin-right: 10px;
        background-color: $back-color;

        &.active {
          background-color: $white-color;
          color: $text-color;
        }
      }

      @include mobile {
        margin: 15px auto 0;
      }
    }
  }
}

.payment-cards {
  @include flex(center, space-around);
  flex-wrap: wrap;

  .single-pay-card {
    padding: 30px;
    background-color: $white-color;
    border-radius: 30px;
    border: 1px solid $border-color;
    min-height: 193px;
    width: 300px;
    margin-right: 15px;
    text-align: center;
    transition: $transition;
    position: relative;
    margin-bottom: 30px;

    .title-card {
      text-transform: uppercase;
      color: $text-color;
      font-weight: 600;
    }

    .price-card {
      color: $main-color;
      font-size: 22px;
      font-weight: 700;
      padding-bottom: 20px;
      border-bottom: 1px solid $border-color;

      span {
        font-size: 10px;
        color: $pargh-color;
        font-weight: 400;
      }
    }

    .list-card {
      margin: 40px 0;
      font-size: 14px;

      .list-card-item {
        margin-bottom: 15px;

        span {
          svg {
            fill: $main-color;
            margin-right: 5px;
          }
        }
      }
    }

    .form-button {
      background-color: $main-color;
      font-size: 14px;
    }

    .the-best {
      font-size: 10px;
      padding: 5px 10px;
      border-radius: 0 6px 6px 0;
      color: $white-color;
      background-color: $text-color;
      position: absolute;
      top: 56px;
      left: 0px;
    }

    &:hover {
      box-shadow: $box-shadow;
    }
  }

  @include mobile {
    display: block;

    .single-pay-card {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
    }

    .form-button {
      padding: $btn-input-padding-mobile;
      width: auto;
      height: auto;
    }
  }
}

.text-purple {
  color: #8055f0
}

.bg-gray {
  background-color: #e0e4ee;
}

.bg-purple {
  background-color: #8055f0;
}

.rounded-lg {
  border-radius: 8px;
}

.text-dark-gray {
  color: #878cac;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-12px {
  font-size: 12px;
}

.fs-16px {
  font-size: 16px;
}

.w-h-100 {
  width: 100px;
}

// .check::before {
//   display: block;
//   position: absolute;
// 	content: '';
//   border-radius: 100%;
//   height: 15px;
//   width: 15px;
//   top: 5px;
// 	left: 5px;
//   margin: auto;
// }
.payments .nav .nav-item {
  width: calc((100% / 3) - 10%);
  margin: 0 5%;
}

.pay-card {
  padding: 30px;
  border: 1px solid $border-color;
  border-radius: $btn-input-radius;
  margin-bottom: 15px;
  position: relative;

  input {
    margin-right: 15px;
  }

  label {
    font-weight: 600;
  }

  img {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  .login-form {
    @include flex(center, space-between);
    flex-wrap: wrap;

    .form-control {
      width: 49%;
    }

    .login-btn {
      width: 100%;
      text-align: center;

      .form-button {
        width: 30%;
      }
    }
  }

  @include mobile {
    padding: 15px;

    img {
      right: 15px;
      top: 15px;
    }
  }
}

.paypal {
  @include flex(center, start);

  p {
    margin: 0;
  }

  .input-paypal {
    margin-right: 15px;
  }

  @include mobile {
    display: block;

    p {
      font-size: 9px;
      margin-top: 10px;
    }

    img {
      width: 30px;
    }
  }
}

.credit {
  flex-wrap: wrap;

  .login-form {
    width: 100%;
    margin-top: 30px;

    .form-control {
      background: transparent;

      input:focus {
        border-color: $main-color;
      }
    }
  }

  img {
    @include mobile {
      width: 100px;
    }
  }
}

input[type="radio"] {
  display: none;
}

input[type="radio"]+label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 2px;
  margin-right: 7px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: $back-color;
  border-radius: 50%;
}

label {
  display: flex;
  align-items: center;
}

input[type="radio"]:checked+label {
  color: $main-color;
}

input[type="radio"]:checked+label:before {
  background-color: $main-color;
}

.p-payment {
  color: $pargh-color;
  font-size: 10px;

  img {
    margin-right: 10px;
  }
}

.input-payment-box {
  >input {
    &:checked {
      &~label {
        background: $back-color;
        border-color: $main-color;

        span {
          font-weight: 600;
        }
      }
    }
  }
}