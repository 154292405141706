.home-page {
  width: calc(100% - 585px);
  min-height: 625px;
  position: absolute;
  padding: 15px;
  box-sizing: border-box;
  top: 0;
  left: 255px;
  background-color: $back-color;
  @include mobile {
    width: 100%;
    left: 0;
    height: 100%;
    margin-top: 50px;
  }
  @include desktop{
    width: calc(100% - 670px);
  }
}