// @import "../index.scss";
// .LinksHeaderList {
//   width: 100%;
//   z-index: 1;
//   @include flex(center, space-between);
//   .list-item {
//     a {
//       text-decoration: none;
//       padding: $btn-input-padding;
//       border-radius: $btn-input-radius;
//       color: $text-color;
//       background-color: $white-color;
//       @include flex(center, center);
//       cursor: pointer;
//       transition: $transition;
//       &:hover {
//         background-color: $text-color;
//         color: $white-color;
//       }
//       &.active {
//         background-color: $text-color;
//         color: $white-color;
//       }
//       .icon {
//         margin-right: 10px;
//       }
//     }d
//   }
// }
.links-page-nav .nav{
  overflow-y: hidden;
}
.nav {
  width: 100%;
  z-index: 1;
  @include flex(center, space-between);
  flex-wrap: nowrap;
  background-color: $white-color;
  @include mobile {
    overflow-x: auto;

  }
  .nav-item {
    width: calc((100% / 7) - 1%);
    @include flex(center, center);
    min-width: 60px;
  }
  .nav-link {
    text-decoration: none;
    padding: 12px 0;
    border-radius: $btn-input-radius;
    color: $text-color;
    // background-color: $white-color;
    @include flex(center, center);
    cursor: pointer;
    transition: $transition;
    width: 130px;
    // width: calc(100%/6);
    border: none !important;
    &.active {
      background-color: $text-color;
      color: $white-color;
    }
    .icon {
      margin-right: 10px;
    }
    @include mobile {
      min-width: 60px;
      padding: $btn-input-padding-mobile;
      margin-bottom: 10px;
      width: 105px;
      strong {
        font-size: 11px;
      }
      .link-svg {
        width: 30px !important;
        height: 30px !important;
        line-height: 28px !important;
        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
}
.nav-tabs {
  border: none;
}
