.login-page {
  @include flex(center, space-between);
  .left-login-side {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $main-color;
    height: 100%;
    width: 50%;
    @include flex(center, center);
    .logo {
      width: 350px;
    }
    .shap1 {
      position: absolute;
      top: 123px;
      left: 449px;
    }
    .shap2 {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    @include mobile {
      display: none;
    }
  }
  .right-login-side {
    padding: 50px 100px;
    width: 50%;
    margin-left: auto;
    min-height: 100vh;
    @include flex(center, unset);
    > div {
      width: 100%;
    }
    .logo-mobile {
      display: none;
    }
    .login-section {
      width: 100%;
      .login-head {
        color: $text-color;
        margin-bottom: 30px;
        @include weight(600);
      }
      .other-login {
        .other-link {
          @include flex(center, center);
          @include linkInput(
            100%,
            transparent,
            $text-color,
            1px solid $border-color,
            48px,
            48px
          );
          max-width: 300px;
          margin-right: auto;
          margin-left: auto;
          .img-link {
            width: 30px;
            @include mobile {
              img {
                width: 15px;
              }
            }
          }
        }
        button.kep-login-facebook {
          width: 100%;
          max-width: 300px;
          border-radius: 5px !important;
          padding: 15px;
        }
      }
      .not-member {
        .sign-login {
          color: $text-color;
          @include weight(600);
        }
      }
    }
    @include mobile {
      width: 100%;
      @include flex(center, unset);
      margin-left: 0;
      padding: 50px 20px;
      background-color: $main-color;
      height: 100%;
      // display: block;
      > div {
        width: 100%;
      }
      .logo-mobile {
        margin-bottom: 20px;
        display: block;
        text-align: center;
        img {
          width: 100px;
        }
      }
      .login-section {
        padding: 20px;
        border-radius: $btn-input-radius;
        background-color: $white-color;
      }
    }
  }
}
.login-form {
  // font-size: 15px;
  .form-control {
    @include form-control(100%, $black-color, transparent);
    .form-input {
      @include linkInput(
        100%,
        transparent,
        $text-color,
        1px solid $border-color,
        48px,
        48px
      );
    }
  }
  .remmeber-forget {
    @include flex(center, space-between);
    .form-control {
      @include flex(center, start);
      input {
        margin-right: 10px;
        &:checked {
          background: $blue-color;
        }
      }
    }

    .forget-password {
      color: $main-color;
      width: 33%;
      font-size: 13px;
      @include mobile {
        font-size: 10px;
        width: 55%;
      }
    }
  }
  .login-btn {
    button {
      @include linkInput(
        100%,
        $main-color,
        $white-color,
        1px solid $border-color,
        48px,
        48px
      );
      @include mobile {
        font-size: 10px;
        @include linkInput(
          100%,
          $main-color,
          $white-color,
          1px solid $border-color,
          28px,
          28px
        );
      }
    }
  }
  .edit-btn {
    @include flex(center, space-between);
    .login-btn {
      width: 47%;
      .cancel-btn {
        background-color: $text-color;
      }
    }
  }

  // .field {
  //   // color: $text-color;

  //   margin-bottom: 20px;
  //   & > * {
  //     display: block;
  //   }
  //   label {
  //     margin-bottom: 14px;
  //   }
  //   input {
  //     width: 100%;
  //     border: none;
  //     outline: none;
  //     background-color: transparent;
  //     color: $pargh-color;
  //     padding: $btn-input-padding;
  //     border-radius: $btn-input-radius;
  //   }
  // }
}

.right-login-side-language {
  position: absolute;
  top: 10px;
  z-index: 10000000;
  width: 40px !important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 90px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  label {
    display: none;
  }
  @include mobile {
    background: #fff;
    top: 115px;

    color: #8055f0;
    font-size: 16px;
  }
}
