.viewPage {
  padding: 50px;
  box-sizing: border-box;
  position: relative;
  .mobile-side-content {
    font-size: 15px;
  }
  .mobile-side-content {
    height: unset;
    width: 400px;
    margin: auto;
    position: relative;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  .personl-img img {
    width: 90px;
  }
  .mobiel-form {
    padding: 50px;
  }
  .social-links {
    a {
      svg {
        height: 70px;
        margin: 0 8px;
      }
    }
  }
  .back-view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $main-color;
    z-index: 2;
  }
  
  @include mobile {
    padding: 20px;
    .mobile-side-content {
      width: 100%;
    }
    .mobiel-form {
      padding: 30px;
    }
  }
}
.logo-view {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 3;
  width: unset;
  @include flex(center, start);
  img{
    width: 120px;
    margin-right: 10px;
  }
  svg{
    margin-top: 10px;
    width: 15px;
    height: 15px;
  }
}