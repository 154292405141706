.appearance-page {
  .apperance-head {
    color: #163152;
    background-color: transparent;
    box-shadow: none;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 16px;

    @include mobile {
      font-size: 10px;
    }
  }

  .avatar-title {

    // @include flex(center, space-between);
    &>div {
      // width: 49%;
      height: 95px;
    }

    .single-item-img {

      // margin-right: 120px;
      @include mobile {
        width: 25px;
      }
    }

    .link-and-icon {
      @include flex(center, end);
    }

    .single-item-switch {
      margin-right: 10px;
    }

    .profile-title {
      color: $text-color;
    }

    .single-item .single-item-info {
      width: 55%;
    }

    @include mobile {
      display: block;

      &>div {
        width: 100%;
        height: 45px;
      }
    }
  }

  .layout {
    @include flex(center, center);
    padding: 20px;
    border: 1px solid $border-color;
    border-radius: 6px;
    font-size: 14px;

    @include mobile {
      padding: 10px;
    }
  }

  .custom-avatars {
    display: inline-flex;
    align-items: flex-end;
    flex-wrap: wrap;

    .avatar {
      margin: 10px;

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0 !important;
        display: block !important;
      }

      .theme-img {
        display: inline-block;
        border-radius: 10px;
        width: 80px;
        height: 80px;
        margin: 0 auto;
        overflow: hidden;
        background-color: firebrick;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          // height: 100%;
          transform: scale(1.2);
        }

        @include mobile {
          width: 40px;
          height: 40px;
        }
      }
    }

    .avatar-back:first-of-type {
      .avatar {
        .theme-img {
          img {
            transform: none;
            height: 100%;
          }
        }
      }
    }
  }

  .avatar,
  .theme-card {
    position: relative;
    margin: 0 20px;
    text-align: center;

    input[type="checkbox"] {
      display: none;
    }

    input[type="radio"]+label:before {
      width: 20px;
      height: 20px;
      padding: 0px;
      border: none;
      position: absolute;
      top: -10px;
      right: -10px;
      background: transparent;
      z-index: 6;
    }

    input.by-checked {
      &:checked {
        &+label .check-icon {
          position: absolute;
          top: -10px;
          right: -3px;
          display: unset;
          z-index: 8;
          background: #fff;
          border-radius: 50%;
          width: 25px;
          height: 25px;
        }

        &+label:before {
          position: absolute;
          top: -10px;
          right: -10px;
          background-color: $white-color;
        }
      }
    }

    .input-active {
      &+label:before {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: $white-color;
      }

      &+label .check-icon {
        position: absolute;
        top: -10px;
        right: -3px;
        display: unset;
        z-index: 8;
        background: #fff;
        border-radius: 50%;
        width: 25px;
        height: 25px;
      }
    }

    .check-icon {
      display: none;
    }

    p {
      display: block;
    }
  }

  .link-dragg {
    text-decoration: none;
    padding: $btn-input-padding;
    border-radius: $btn-input-radius;
    color: $white-color;
    background-color: $text-color;
    position: relative;
    @include flex(center, start);
    margin-bottom: 10px;

    .icon {
      margin-right: 10px;
      // svg{
      //   path{
      //     fill: $white-color;
      //   }
      // }
      // .social{
      //   svg {
      //     rect {
      //       fill: transparent;
      //       stroke: $white-color;
      //     }
      //     path:nth-of-type(1),
      //     path:nth-of-type(2) {
      //       fill: $white-color;
      //       stroke: transparent;
      //     }
      //   }
      // }
    }

    .drag-img {
      width: 10px;
      position: absolute;
      left: 10px;
      height: 10px;
    }
  }

  .avatar-theme-list,
  .buttons-style-shap-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      display: inline-block;
      border-radius: 25px;
      background-color: $back-color;
      color: $pargh-color;
      cursor: pointer;
      transition: $transition;
      padding: 15px 0;
      width: 100px;
      text-align: center;
      margin-bottom: 15px;
      margin-right: 10px;

      .avatar-theme-image {
        width: 100%;
        @include flex(center, center);
      }

      p {
        margin: 10px 0 0 0;
      }

      &:hover {
        box-shadow: $box-shadow;
        background-color: $main-color;
        color: $white-color;
      }

      @include mobile {
        width: 60px;
        border-radius: $btn-input-radius;
        margin-bottom: 10px;

        .avatar-theme-image {
          img {
            width: 30px;
          }
        }

        p {
          font-size: 10px;
        }
      }
    }
  }

  .buttons {
    @include flex(start, space-between);
    box-sizing: border-box;
    color: $grad-color;

    .buttons-style,
    .buttons-icon-style {
      width: 50%;
      min-height: 220px;
    }

    .buttons-style-shap-list {
      li {
        background: none;
        margin-right: 10px;
        border-radius: $btn-input-radius;
        width: 90px;
      }
    }

    @include mobile {
      display: block;

      .buttons-style,
      .buttons-icon-style {
        width: 100%;
      }
    }
  }

  .upload-button {
    @include flex(flex-start, start);

    >div {
      width: 40%;
    }

    @include mobile {
      display: block;

      >div {
        width: 100%;
      }
    }
  }

  .font-section {
    position: relative;
  }

  .font-style-tabs {
    .nav {
      position: absolute;
      @include flex(center, center);
      background-color: hsla(0, 0%, 95%, 0.612);
      width: 77px;
      padding: 2px 5px;
      margin: 0;
      top: -7px;
      left: 78px;
      z-index: 2;

      .nav-item {
        width: 29px;
        height: 29px;
        line-height: 29px;

        .nav-link {
          border-radius: 6px;
          font-size: 13px;
          width: 29px;
          height: 29px;
          line-height: 29px;
          background-color: #f3f3f39c;
          font-weight: 600;
          padding: 0;

          @include mobile {
            min-width: 29px;
            margin-bottom: 0;
            font-size: 10px;
          }
        }

        .nav-link.active {
          background-color: #fff;
          color: #163152;
        }
      }

      @include mobile {
        top: 2px;
      }
    }

    li {
      background: none;

      &:hover {
        background: none;
        box-shadow: none;
      }
    }
  }

  .links-align {
    @include flex(center, start);

    .avatar {
      @include mobile {
        margin: 0 5px;
      }
    }

    .form-button {
      width: 130px;
      margin-right: 15px;
      padding: 10px 0;
      background-color: #f3f3f39c;
      height: auto;
      line-height: 0;
      color: $text-color;

      svg {
        margin-right: 5px;
      }

      @include mobile {
        width: 75px;
        margin-right: 0;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      // &:hover{
      //   background-color: $main-color;
      //   color: $white-color;
      //   svg{
      //     path{
      //       fill: $white-color;
      //     }

      //   }
      // }
    }

    .pro-btn .form-button,
    .new-btn .form-button {
      width: auto;
      background-color: #8055f0;
      font-size: 9px;
      padding: 10px 5px;
      display: inline-block;
      height: auto;
      line-height: unset;
      margin-top: 5px;
      border-radius: 3px;
      color: #fff;
    }
  }

  .links-align>button:hover {
    background-color: $main-color;
    color: $white-color;

    svg {
      path {
        fill: $white-color;
      }
    }
  }

  .description {
    p {
      margin-bottom: 10px;
      color: $grad-color;
    }

    // .form-control {
    //   width: 100%;
    // }
  }

  .pro-btn,
  .new-btn {
    margin: 0 5px;
    position: absolute;

    .form-button {
      width: auto;
      margin: 0;
    }
  }

  .font-pro {
    .form-button {
      background-color: $text-color !important;
    }
  }

  .title-pro {
    .form-button {
      background-color: $white-color !important;
      color: $text-color !important;
      border: 1px solid $border-color;
    }
  }

  // .appearance-page{
  //   p{
  //     margin-bottom: 10px;
  //   }
  // }
  .font-style {
    >p {
      font-weight: 600;
      color: $grad-color;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
  }

  .font-style {
    position: relative;

    .nav-tabs {
      position: absolute;
      top: 0;
      left: 100px;
    }
  }

  // .avatar-theme-list {
  //   li {
  //     @include mobile {
  //       width: 60px;
  //       border-radius: $btn-input-radius;
  //       margin-bottom: 10px;
  //       .avatar-theme-image {
  //         img {
  //           width: 60px;
  //         }
  //       }
  //       p {
  //         font-size: 10px;
  //       }
  //     }
  //   }
  // }
}

.button-color {
  color: $grad-color;
  @include flex(center, flex-start);
  flex-wrap: wrap;

  @include mobile {
    display: block;

    p {
      margin-bottom: 10px;
    }
  }

  >.input-color-box-parent {
    min-width: 33%;
  }

  input[type="color"] {
    width: 50px;
  }
}

.social-icon {
  p {
    color: $grad-color;
    margin-bottom: 10px;
  }

  .high-title,
  .high-details {
    margin: 15px 0;
  }

  .with-border {
    @include flex(center, space-between);
    border: 1px solid $border-color;
    border-radius: 6px;
    padding: 20px 0 20px 20px;

    p {
      margin: 0;
      font-weight: 600;
    }
  }
}

.leftAligh {
  .pro-btn {
    position: absolute;
    top: -2px;
    left: 10px;
  }
}

.align-pro {

  .pro-btn,
  .new-btn {
    display: inline;
    position: absolute;
    top: 0;
    left: 10px;

    transform: translate(-50%, -50%);

    .form-button {
      font-size: 8px;
      margin: 0 !important;
    }

    @include mobile {
      left: 40%;

      .form-button {
        font-size: 6px;
      }
    }
  }

  .new-btn {
    top: 20px;
  }
}

.high-header {
  @include flex(center, space-between);

  p {
    margin: 0;
    color: $pargh-color;
    font-size: 12px;

    @include mobile {
      font-size: 8px;
    }
  }
}

.accordion-button:focus {
  border: none;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: $main-color;
  background-color: transparent;
  box-shadow: none;
}

.accordion-item {
  border: none;
  font-size: 12px;
  border-bottom: 1px solid $border-color;
}

.accordion-button {
  color: $text-color;
  background-color: transparent;
  box-shadow: none;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;

  @include mobile {
    font-size: 10px;
  }
}

.accordion-button::after {
  background-image: url(../../images/dawon.png);
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../../images/dawon.png);
  transform: rotate(-90deg);
}

// .high-title {
//   margin-top: 20px;
//   .form-page{
//     display: block
//   }
//   .form-page > div {
//     @include flex(center, start);
//     width: 100%;
//     margin-bottom: 15px;
//   }

//   .high-title-head {
//     padding: 0 30px;
//     width: 155px;
//     background-color: $back-color;
//     color: $text-color;
//     font-weight: 600;
//     height: 48px;
//     line-height: 48px;
//     margin: 0;
//   }
// }
// .form-input{
//   padding: 12px 30px;
//   border-radius: 6px;
//   background-color: #f3f3f39c;
//   color: #969BA3;
//   outline: none;
//   border: none;
//   width: 100%;
//   height: 48px;
//   line-height: 48px;

// }
// .field-array{
//   display: block;
// }
.highlights {

  .high-title,
  .high-details {
    margin: 15px 0;
    @include flex(center, start);
    width: 100%;

    .high-title-head {
      width: 22%;
      border-radius: 6px 0 0 6px;
      background-color: #f3f3f39c;
      color: #969ba3;
      height: 48px;
      line-height: 48px;
      padding: 0 10px;
      border-right: 1px solid $border-color;

      @include mobile {
        padding: 0 10px;
        height: 30px;
        line-height: 30px;
        font-size: 8px;
        margin-bottom: 5px;
        width: 37%;
      }
    }

    .form-input {
      width: 100%;
    }
  }

  .field-array {
    .high-details {
      background: #f3f3f39c;
      padding: 0 10px;

      .trash-icon {
        background: var(--bs-danger);
        padding: 10px;
        border-radius: 5px;

        path {
          fill: #fff !important;
        }
      }

      .form-input {
        width: 100%;
        margin-right: 0;
        padding: $btn-input-padding;
        border-radius: $btn-input-radius;
        background-color: $back-color;
        color: $grad-color;
        outline: none;
        border: none;
        height: 48px;
        line-height: 48px;
      }
    }

    .form-button {
      width: 100%;
    }

    @include mobile {
      .form-input {
        width: 100%;
        padding: 0 10px;
        height: 30px;
        line-height: 30px;
        font-size: 10px;
        margin-bottom: 5px;
        width: 70%;
      }

      .form-button {
        font-size: 9px;
      }
    }
  }
}

// .upload__image-wrapper button {
//   border: 1px solid #ccc;
//   box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
// }