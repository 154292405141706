.link-page,
.social-page,
.location-page,
.banks-page,
.link-performance-charts,
.device-category-charts,
.mobile-device,
.payments,
.settings,
.images,
.pdf,
.menu-page,
.appearance-page,
.messages,
.index {
  position: relative;
  background-color: $white-color;
  padding: 30px;
  border-radius: $btn-input-radius;
  @include mobile {
    padding: 15px;
  }
  // .your-links {
  //   .single-item, {
  //     padding: 20px;
  //     border: 1px solid $border-color;
  //     border-radius: $btn-input-radius;
  //     @include flex(center, space-between);
  //     .single-item-icon,
  //     .single-item-img {
  //       width: 40px;
  //       height: 40px;
  //       background-color: $blue-color;
  //       color: $white-color;
  //       border-radius: $btn-input-radius;
  //       @include flex(center, center);
  //     }
  //     .single-item-info, {
  //       width: 60%;
  //       .name-from-link {
  //         font-weight: 700;
  //         margin-bottom: 5px;
  //         margin: 0;
  //       }
  //       .the-link {
  //         color: $blue-color;
  //         font-size: 12px;
  //         img {
  //           margin-right: 5px;
  //         }
  //       }
  //     }
  //     .link-action {
  //       color: $text-color;
  //       cursor: pointer;
  //       .edit-icon,
  //       .trash-icon {
  //         padding: 10px;
  //         background-color: $text-color;
  //         color: $white-color;
  //         border-radius: $btn-input-radius;
  //         margin-right: 5px;
  //       }
  //       .trash-icon {
  //         background-color: $back-color;
  //         color: $text-color;
  //       }
  //       svg {
  //         width: 18px;
  //       }
  //     }
  //   }
  // }
}
.not-pro-analytic {
  .mobile-device,
  .device-category-chart {
    min-height: 300px;
  }
}

.eQWBcd {
  svg {
    width: 10px !important;
  }
}
.hQZhQk {
  svg {
    width: 20px !important;
    fill: $nav-color !important;
  }
}
.grnfGc {
  background-color: $back-color !important;
  box-shadow: none !important;
  border-radius: $btn-input-radius !important;
}
.dnuJgB img {
  border-radius: $btn-input-radius !important;
}
@include mobile {
  .hQZhQk {
    svg {
      width: 15px !important;
    }
  }
  .grnfGc {
    width: 35px !important;
    height: 35px !important;
  }
}

.links-page {
  .nav-item {
    .nav-link {
      width: 85px;
      position: relative;
      > div {
        // @include flex(center, spdace-between);
        font-size: 14px;
        .link-svg {
          height: 40px;
          width: 40px;
          line-height: 40px;
          border-radius: 50%;
          background-color: $back-color;
          margin: 0 auto;
          margin-bottom: 5px;
          svg {
            height: 35px;
            filter: invert(62%) sepia(22%) saturate(367%) hue-rotate(194deg)
              brightness(86%) contrast(90%);
          }
        }
        strong {
          color: #878cac;
        }
      }
    }
    .nav-link.active {
      background-color: rgba(129, 85, 240, 0.2);

      color: #8055f0;
      .link-svg {
        background-color: #8055f0;
      }
      svg {
        filter: none !important;
        path {
          fill: $white-color;
        }
      }
      strong {
        color: #8055f0 !important;
      }
    }
  }
  li:nth-of-type(6) {
    .nav-link {
      svg {
        path {
          fill: transparent;
          stroke: $text-color;
        }
      }
    }
    .nav-link.active {
      svg {
        path {
          fill: transparent;
          stroke: $white-color;
        }
      }
    }
  }
  li:nth-of-type(2) {
    .nav-link.active {
      svg {
        rect {
          fill: transparent;
          stroke: $white-color;
        }
        path:nth-of-type(1),
        path:nth-of-type(2) {
          fill: $white-color;
          stroke: transparent;
        }
      }
    }
  }
}
.soical-drag {
  .single-item {
    background-color: $white-color;
  }
}
.social-page {
  .single-item .link-action {
    @include mobile {
      justify-content: space-between;
    }
  }
  .link-and-icon {
    width: 40% !important;
    @include mobile {
      width: 100% !important;
    }
  }
  .button-icon {
    display: flex;
    align-items: center;
    margin: 0 5px;
    font-size: 13px;

    label {
      height: 40px;
      line-height: 40px;
      width: 80px;
      background-color: white;
      justify-content: center;
      border: 1px solid #c8cacf;
      color: #c8cacf;
      &:first-of-type {
        border-radius: 10px 0 0 10px;
      }
      &:nth-of-type(2) {
        border-radius: 0 10px 10px 0;
      }
      &.input-selected {
        background-color: #8cc8cc21;
        border-color: $text-color;
        color: $text-color;
      }
      @include mobile {
        height: 20px;
        line-height: 20px;
        width: 50px;

        &:first-of-type {
          border-radius: 5px 0 0 5px;
        }
        &:nth-of-type(2) {
          border-radius: 0 5px 5px 0;
        }
      }
    }
    span {
      margin: 0 5px;
      svg {
        margin: 0 5px;
        path {
          fill: #c8cacf;
        }
      }
    }
    @include mobile {
      font-size: 10px;
    }
  }
  @include mobile {
    .social-content {
      .single-item {
        display: block;
      }
    }

    .link-action {
      margin-top: 15px;
    }
  }
}
