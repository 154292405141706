.code-inputs {
  @include flex(center, center);
  border: 1px solid $border-color;
  padding: $btn-input-padding;
  border-radius: $btn-input-radius;
  margin: 15px 0;
  input {
    width: 60px !important;
    text-align: center;
    padding: 0;
    outline: none;
    width: 100%;
    line-height: 1.5;
    background-clip: padding-box;
    border: none;
    font-size: 14px;
    border-bottom: 3px solid #ccc;
    border-radius: 0;
    margin: 0 5px;
    height: 40px;
    font-size: 20px;
    &:focus{
      box-shadow: none;
      border-color:#8055f0 ;
    }
  }
}
