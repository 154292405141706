.link {
  text-decoration: none !important;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
@import "./variables";
@import "./mixin";
@import "./breakpoint";
@import "./upload-img";
@import "./components/homeHeader";
@import "./components/sidebar";
@import "./components/mobileSide";
@import "./components/linksHeader";
@import "./pages/home";
@import "./pages/login";
@import "./pages/analytic";
@import "./pages/messages";
@import "./form-new";
@import "./switchButton";
@import "./pages/appearance";
@import "./pages/links";
@import "./pages/payments";
@import "./components/images";
@import "./pages/view";
@import "./pages/verifyCode";
@import "./components/linksPageHeader";
@import "./components/singleLink";
@import "./components/pdf";
@import "./modal";
@import "./rtl";

.img-upload .img-uploadded {
  width: 40px !important;
  height: 40px;
  object-fit: contain;
  @include mobile {
    width: 20px !important;
    height: 20px;
  }
}

.mobile-loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 100;
  width: 247px;
  height: 477px;
  background-color: #163152;
  position: absolute;
  top: 62px;
  right: 37px;
}

.mobile-loading-box img {
  width: 100px;
}
.social-list-item {
  display: flex;
  align-items: center;
}
.social-list-item-icon {
  background: #8cc8cc;
  border-radius: 7px;
  padding: 7px;
  box-sizing: border-box;
}
.social-list-item-icon img {
  width: 25px;
  height: 25px;
}

.social-list-item .text {
  font-weight: bold;
  margin: 0 10px;
}
@include mobile {
  .social-list-item-icon img {
    width: 15px;
    height: 15px;
  }
  .social-list-item .text {
    font-size: 8px;
  }
}

.theme-style .avatar label {
  height: 150px;
  width: 100px;
  img:nth-of-type(2) {
    width: 100px;
  }
  @include mobile {
    width: 60px;
    height: 110px;
    font-size: 10px;
    img:nth-of-type(2) {
      width: 60px;
    }
  }
}
.location-form {
  .form-control {
    margin: 0 10px 20px;
    input {
      border-radius: 50px;
    }
  }
  button {
    border-radius: 50px;
  }
  @include mobile {
    flex-wrap: wrap;
  }
}

.success-page {
  background: #f5f5f5;
  padding-top: 70px;
  .success-img img {
    border-radius: 10px;
    width: 100%;
    margin-bottom: 50px;
    max-height: 200px;
  }

  .success-title {
    text-align: center;
  }
  .success-title h2 {
    color: #000;
    font-weight: bold;
  }
  .success-title p {
  }
  .font-weight-bold {
    color: #000 !important;
    font-size: 16px;
  }

  .success-list {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }

  .success-page .bg-light {
    background: #fff !important;
  }
  .field-status {
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
  }
  @media (max-wdith: 700px) {
    .field-status {
      display: inline-block;
      margin-top: 15px;
    }

  }

}
.link-and-icon.imgg {
  margin-right: auto;
}

.info-box {
  position: absolute;
  right: 10px;
  top: 30px;
  padding-top: 27px;
}

.info-box-icon {
  position: absolute;
  right: 0;
  top: -25px;
}

.info-box-icon svg {
  width: 20px;
  height: 20px;
}

.info-box-text {
  display: none;
  padding: 5px;
  font-size: 9px;
  background: red;
  color: #fff;
  border-radius: 5px;
  width: 300px;
  max-width: 300px;
  position: absolute;
  bottom: 150%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%);
}

.info-box-text span {
}

.info-box:hover .info-box-text {
  display: block;
}

@media (max-width: 700px) {
  .info-box-text {
    right: 0;
    left: auto;
    transform: translate(0);
  }
}

.locked-content__component .locked-content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.locked-content__component .locked-content-header .locked-content-header-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url(https://cdn-f.heylink.me/static/media/ic_locked.87def6e8.svg);
  width: 160px;
  height: 70px;
  margin: 0 auto 10px;
}
.locked-content__component .locked-content-header .locked-content-header-text {
  line-height: 1.4;
  text-align: center;
  text-shadow: 1px 1px 2px #fff, 0 0 1em #fff;
  color: #878cac;
}
.locked-content__component
  .locked-content-header
  .locked-content-header-text
  a {
  color: #2975cf;
  text-decoration: underline;
}
.locked-content__component {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  z-index: 2;
}
.locked-content__component .locked-content-wrapper {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  opacity: 0.9;
  pointer-events: none !important;
  touch-action: none !important;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
}

.text-truc {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.mobile-device .table-responsive {
  max-height: 500px;
  overflow: scroll;
}

.img-upload-btn img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.btn-save-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.btn-save-box button {
  line-height: 1;
  background: var(--bs-indigo);
}

.form-group-category {
  position: relative;
  margin: 0 10px;

  label {
    font-size: 11px;
    position: absolute;
    background: #8055f0;
    top: -8px;
    left: 10px;
    color: #fff;
    padding: 0px 5px;
    border-radius: 5px;
    font-weight: 600;
  }
  select {
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding-top: 12px;
    outline: navajowhite;
  }
}

.single-item-menu-body {
  padding: 15px;
  border: 1px solid rgba(222, 227, 237, 0.54);
  border-top: navajowhite;
  margin-bottom: 30px;
  .list-menu-items {
    padding-bottom: 20px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
  }
}

.list-menu-items > h2 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}
.list-category-item {
  background: #f3f8ff;
  padding: 20px 10px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.list-category-item h2 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.list-menu-item {
  display: flex;
}

.list-menu-item-inputs {
  flex: 1;
}

.list-menu-item-inputs-top {
  display: flex;
  margin-bottom: 20px;
}
.list-menu-item-inputs-top > div {
  width: 100%;
}
.list-menu-item-inputs-top input {
  width: 100%;
  height: 50px;
  background: #f4f5f9;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #ccc;
}

.list-menu-item-inputs-top .list-menu-item-inputs-item-price {
  width: 200px;
}
.list-menu-item-inputs-top .list-menu-item-inputs-item-price input {
  width: 200px;
}
.react-tel-input .form-control {
  border-radius: 6px;
  background-color: #f3f3f39c !important;
  color: #969ba3;
  outline: none;
  border: none;
  height: 48px !important;
  line-height: 48px !important;
  display: block;
  width: 100% !important;
  &:focus {
    box-shadow: none !important;
  }
}

.add-more-item {
  display: flex;
  align-items: center;
  background: #163152;
  margin-top: 40px;
  svg {
    margin: 0 5px;
    path {
      fill: #fff;
    }
  }
  span {
    margin: 0 5px;
    color: #fff;
  }
  &.item-btn {
    background: #8055f0;
  }
}

.add-phone-link {
  background: none;
  border: none;
  margin-top: 10px;
  color: #03a9f4;
  font-weight: bold;
  font-size: 14px;
  display: block;
  text-align: center;
  width: 100%;
}
.background-back {
  .img-upload-btn {
    img,
    svg {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
.login-section .nav .nav-item {
  width: 50%;
}

.login-section .nav .nav-item button {
  width: 90%;
  border-bottom: 2px solid #ccc !important;
  border-radius: 0;
}

.login-section .nav .nav-item button.active {
  border: navajowhite;
  border-radius: 10px;
}
.react-tel-input {
  direction: ltr;
}

.btn-save-box button b {
  margin: 0 5px;
  position: relative;
  z-index: 2;
}

.btn-save-box button span {
  position: relative;
  z-index: 2;
}

.btn-save-box-bg {
  display: block;
  position: absolute;
  background: #6610f2;
  height: 100%;
  top: 0;
  border-radius: 5px;
  left: 0;
}

.btn-save-box button {
  position: relative;
}

.theme-card {
  width: calc(170px + 30px);
  margin: 0 15px 30px !important;
  .image-block {
    position: relative;
    height: 360px;
    width: 100%;
    .theme-img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 10px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // transform: scale(1.3);
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff6;
        height: 100%;
        content: "";
        display: none;
      }
    }
    .check-icon {
      position: absolute;
      left: 5px;
      background: #fff;
      border-radius: 50%;
    }
    .lines {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      display: none;

      span {
        display: block;
        width: 150px;
        height: 25px;
        background: #fff;
        margin-bottom: 10px;
        border-radius: 50px;
        opacity: 0.8;
        &:nth-last-child(1) {
          margin: 0;
        }
      }
    }
  }
  input {
    display: none;
  }
  label {
    &::before {
      display: none !important;
    }
  }
  h4 {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    margin-top: 15px;
  }
  &.custom-card {
    .image-block {
      border: 2px dashed;
      border-radius: 10px;
      .lines {
        display: none;
      }
    }
  }
  .new-btn {
    position: absolute;
    left: -35px;
    top: 10px;
    right: auto;
    z-index: 2;
    button {
      font-size: 11px !important;
      width: 100px !important;
      transform: rotate(-45deg);
    }
  }
  @media (max-width: 550.98px) {
    width: calc(180px);
    margin: 0 0 30px !important;
    .image-block {
      height: 250px;
    }

  }
  @media (max-width: 430.98px) {
    width: calc(150px);
    margin: 0 0 30px !important;
    .image-block {
      height: 270px;
    }
  }
  @media (max-width: 360.98px) {
    width: calc(130px);
    margin: 0 0 30px !important;
    .image-block {
      height: 180px;
    }
  }
}

.themes-list {
  justify-content: space-between !important;
  margin-right: -15px;
  margin-left: -15px;
}

.cus-pro-btn {
  button {
    position: absolute;
    top: 10px;
    background: #000;
    color: #fff;
    font-size: 14px;
    right: 10px;
    padding: 5px 10px;
    svg {
      width: 20px;
    }
    &:hover {
      color: #fff;
      background: #8055f0;
    }
  }
}

.themes-catgs {
  margin-bottom: 30px;
  margin-right: -10px;
  margin-left: -10px;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 10px;
  .nav-item {
    margin: 0 10px;
    min-width: auto;
    flex: 1;
    padding-left: 10px;
    width: auto;
    position: relative;
    padding: 0;
    &::before {
      height: 100%;
      width: 2px;
      top: 3px;
      content: "";
      position: absolute;
      left: 0;
      background: #f1f1f1;
    }
    &:nth-last-child(1) {
      &::before {
        display: none;
      }
    }
    &.active {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: calc(100% + 20px);
        height: 2px;
        bottom: -12px;

        background: #8055f0;
      }
    }
    .nav-link {
      font-size: 17px;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      white-space: nowrap;
      width: auto;
      padding: 0;
      text-align: center;
      &.active {
        background-color: transparent;
        color: #163152;
      }
    }
  }
}

.link-action.fav {
  justify-content: flex-start;
  .trash-icon {
    background-color: var(--bs-danger);
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.list-card-item{
  text-align: left;
}
.theme-card h4{
  height: 20px;
}

@media only screen and (max-width: 600px) {
  .rocket-img{
    max-width: 150px;
  }

}
