.your-links-header {
  font-size: 15px;
  font-weight: 600;
  color: $text-color;
  .icon {
    color: $grad-color;
    margin-left: 5px;
  }
  @include mobile {
    font-size: 12px
  }

}