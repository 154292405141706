.ReactModal__Content,
.ReactModal__Content--after-open {
  width: 450px;
  border-radius: $btn-input-radius !important;
  border: none !important;
  box-shadow: $box-shadow;
  padding: 30px !important;
  margin: auto;
  @include flex(center, center);
  // min-height: 300px;
  > div {
    width: 100%;
  }
  h4 {
    margin-bottom: 30px;
    color: $text-color;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
  }
  @include mobile {
    width: 250px;
    padding: 10px !important;
    h4 {
      margin-bottom: 20px;
      font-size: 12px;
    }
  }
}
.ReactModal__Overlay ,.ReactModal__Overlay--after-open{
  z-index: 99;
  position: relative;
}
.modal-form {
  .form-control {
    // width: 100%;
    .form-input {
      background-color: transparent;
      color: $grad-color;
      border: 1px solid $border-color;
    }
  }
}
