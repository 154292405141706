.analytic {
  .analytic-header {
    @include flex(center, start);
    margin-bottom: 15px;
    .form-page {
      margin-right: 15px;
      .form-control {
        width: 130px;
        .form-input {
          padding: 12px;
          color: $text-color;
          background-color: $white-color;
          @include mobile {
            padding: 0;
          }
        }
      }
    }
  }
  .analytic-info {
    .analytic-info-list {
      width: 100%;
      @include flex(center, space-between);
      .list-item {
        height: 100px;
        padding: 0 10px;
        border-radius: $btn-input-radius;
        color: $text-color;
        background-color: $white-color;
        position: relative;
        @include flex(center, start);
        cursor: pointer;
        transition: $transition;
        width: 170px;
        .icon {
          margin-right: 15px;
          height: 50px;
          width: 50px;
          line-height: 50px;
          background-color: $main-color;
          text-align: center;
          border-radius: $btn-input-radius;
          color: $white-color;
        }
        .info {
          font-size: 11px;
          .title {
            color: $pargh-color;
          }
          .number {
            color: $main-color;
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
      @include mobile {
        display: block;
        .list-item {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }
  .link-performance-charts {
    .charts > div {
      width: 49%;
      margin-right: 1%;
      display: inline-block;
      @include mobile {
        width: 100%;
      }
    }
  }
  .row-2 {
    display: flex;
    .device-category-charts,
    .mobile-device {
      display: inline-block;
      width: 49%;
      min-height: 330px;
    }
    @include mobile {
      display: block;
      .device-category-charts,
      .mobile-device {
        width: 100%;
        display: block;
      }
    }
  }
}

.black-btn {
  .form-button {
    background-color: $text-color;
    font-size: 13px;
    width: 154px;
  }
}
