.mobile-side {
  width: 330px;
  position: fixed;
  top: 0;
  right: 0;
  text-align: center;
  transition: $transition;
  z-index: 8;
  img {
    width: 100%;
  }

  @include mobile {
    right: -100%;
  }
  @include desktop {
    width: 415px;
  }
  .mobile-side-content , iframe {
    width: 247px;
    height: 477px;
    background-color: $main-color;
    position: absolute;
    top: 62px;
    right: 32px;
    border-radius: 5px;
    z-index: 1;
    text-align: center;
    color: $white-color;
    font-size: 12px;
    padding: 0 0;
    box-sizing: border-box;
    overflow-y: scroll;
    .personl-img {
      img {
        width: 60px;
        border-radius: 50%;
      }
    }
    @include mobile {
      top: 92px;
    }
    @include desktop {
      width: 308px;
      right: 47px;
      height: 616px;
    }
  }
}

.links {
  margin-top: 15px;
  p {
    margin: 0 auto 20px;
    width: 93%;
    border-radius: $btn-input-radius;
    background-color: $white-color;
    padding: $btn-input-padding;
    a {
      color: $text-color;
      text-decoration: none;
    }
    svg {
      fill: $text-color;
      width: 15px;
      margin-left: 10px;
    }
  }
}
.social-links {
  margin-bottom: 15px;
  a {
    text-decoration: none;
    color: $white-color;
    svg {
      width: 25px;
      margin: 0 2px;
    }
  }
}
.mobiel-form {
  width: 93%;
  margin: auto;
  background-color: $white-color;
  padding: 15px;
  border-radius: $btn-input-radius;
  .form-control {
    width: 100%;
    .form-input {
      background-color: transparent;
      border-bottom: 1px solid $border-color;
      padding: 10px 5px;
      margin-bottom: 15px;
      border-radius: 0;
      font-size: 12px;
    }
  }
  .mobile-button {
    width: 100%;
    padding: 10px 5px;
    border: none;
    outline: none;
    border-radius: $btn-input-radius;
  }
}
.icon-view {
  display: none;
  z-index: 8;
  .form-button {
    width: 35px;
    border-radius: 15px 0 0 15px;
    background-color: $main-color;
  }
  @include mobile {
    display: block;
    position: fixed;
    top: 10px;
    right: 0;
  }
}
.mobile-side.show {
  @include mobile {
    right: 0;
    z-index: 10;
  }
}